import Head from 'next/head';
import { ReactNode } from 'react';

import styles from './LoginLayout.module.scss';

export const LoginLayout = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => {
  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link href="/favicon.ico" rel="icon" />
        <link href="/favicon.png" rel="icon" type="image/png" />
      </Head>
      <div className={styles.wrapper}>
        <main className={styles.main}>{children}</main>
      </div>
    </>
  );
};
