import { Button } from '@mui/material';
import type { LoginUserRequest } from '@on3/api';
import { useAuth } from '@on3/ui-lib';
import { externalApi } from '@on3/ui-lib/utils/api';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import useToast from '../Contexts/ToastProvider';
import { BaseTextInput } from '../Form/BaseTextInput';
import styles from './LoginForm.module.scss';

export const LoginForm = () => {
  const { error } = useToast();
  const { setUserToken } = useAuth();
  const router = useRouter();
  const { control, handleSubmit } = useForm<LoginUserRequest>({});
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<LoginUserRequest> = (loginData) => {
    setIsLoading(true);
    externalApi
      .post('/users/v1/users/login/', loginData)
      .then((response) => {
        setUserToken(response.data);
        const hasGraphicEdits =
          response?.data?.roles?.includes('rdb_graphic_edits');
        const hasRdb1 = response?.data?.roles?.includes('rdb_graphic_edits');
        const hasPublisher =
          response?.data?.roles?.includes('admin_publisher_dashboard') ||
          response?.data?.roles?.includes('publisher_dashboard');

        if (hasGraphicEdits && !hasRdb1) {
          router.push('/nil/graphic-requests/');
        } else if (hasPublisher && !hasRdb1) {
          router.push('/publisher/');
        } else {
          router.push(
            router?.query?.returnto
              ? (router?.query?.returnto as string)
              : '/people/',
          );
        }
      })
      .catch((e) => {
        error('There was an error loggin in.');
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <fieldset className={styles.fieldset}>
            <BaseTextInput control={control} label="username" name="username" />
            <BaseTextInput
              control={control}
              label="password"
              name="password"
              type="password"
            />
            <div className={styles.bottom}>
              <Button
                className={styles.login}
                color="primary"
                disabled={isLoading}
                size="small"
                type="submit"
                variant="contained"
              >
                Login
              </Button>
            </div>
          </fieldset>
        </form>
      </div>
    </section>
  );
};
