import { TextField } from '@mui/material';
import type { TextFieldProps } from '@mui/material/TextField';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';

import styles from './BaseTextInput.module.scss';

// once these are built out, we can get more custom here
interface BaseTextInputProps extends Omit<TextFieldProps, 'name' | 'control'> {
  name: string;
  control: Control<any>;
  handleChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
}

export const BaseTextInput: React.FC<BaseTextInputProps> = ({
  name,
  control,
  maxRows = 1,
  error,
  helperText,
  InputProps,
  InputLabelProps,
  className,
  multiline,
  handleChange,
  placeholder,
  ...rest
}) => {
  const currentInputProps = useMemo(() => {
    if (handleChange) {
      return { ...InputProps, onChange: handleChange };
    }

    return { ...InputProps };
  }, [InputProps, handleChange]);

  const RenderedInput = useCallback(
    // @ts-expect-error react-hook-form typing
    ({ field, fieldState, formState }) => {
      return (
        <TextField
          {...field}
          {...rest}
          InputLabelProps={InputLabelProps}
          InputProps={currentInputProps}
          className={clsx(styles.inputStyles, field.className, className)}
          error={error || formState?.errors[name]}
          helperText={helperText || formState?.errors[name]?.message}
          maxRows={maxRows}
          multiline={multiline}
          placeholder={placeholder ?? ''}
        />
      );
    },
    [
      InputLabelProps,
      className,
      currentInputProps,
      error,
      helperText,
      maxRows,
      multiline,
      name,
      placeholder,
      rest,
    ],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={RenderedInput}
      {...rest}
    />
  );
};
