import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import { GetServerSideProps } from 'next';
import { LoginLayout } from 'src/components/Layout/LoginLayout';
import { LoginForm } from 'src/components/LoginForm/LoginForm';

const Login = () => {
  return (
    <LoginLayout title="Login">
      <LoginForm />
    </LoginLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const userData = await withAuthentication.isAuth(ctx);

  if (
    !userData?.rg?.includes('developer') &&
    !userData?.rg?.includes('rdb5') &&
    !userData?.rg?.includes('rdb4') &&
    !userData?.rg?.includes('rdb3') &&
    !userData?.rg?.includes('rdb2') &&
    !userData?.rg?.includes('rdb1') &&
    !userData?.rg?.includes('administrator')
  ) {
    // Todo: We need a landing page to handle roles and list them off, instead of redirecting

    if (userData?.rg?.includes('cs_admin')) {
      return {
        redirect: {
          destination: `/users`,
          permanent: false,
        },
      };
    }

    if (userData?.rg?.includes('rdb_graphic_edits')) {
      return {
        redirect: {
          destination: `/nil/graphic-requests/`,
          permanent: false,
        },
      };
    }

    if (
      userData?.rg?.includes('publisher_dashboard') ||
      userData?.rg?.includes('admin_publisher_dashboard')
    ) {
      return {
        redirect: {
          destination: `/publisher/`,
          permanent: false,
        },
      };
    }

    return {
      props: {},
    };
  }

  return {
    redirect: {
      destination: `/people`,
      permanent: false,
    },
  };
};

export default withAuthentication(Login);
